<template>
  <v-form
    ref="form"
    class="d-inline-flex flex-nowrap"
  >
    <v-row
      align="center"
      no-gutters
    >
      <!-- target alias -->
      <v-col class="primary--text text-truncate ml-2" style="font-size: 1.25rem;">
        <!--  alias normal model -->
        <v-row v-if="!isEditAlias" no-gutters align="center" style="height: 100%;">
          <v-col cols="auto">
            {{ alias }}
          </v-col>
          <v-col class="ml-2" cols="auto">
            <v-btn color="primary" icon small @click.native.stop="editAlias">
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--  alias edit model -->
        <v-row v-else no-gutters align="center">
          <v-col cols="auto">
            <v-text-field
              v-model="alias"
              class="mb-1"
              hide-details="auto"
              dense
              autofocus
              @blur="doneEditAlias"
              @keyup.space.stop.prevent
              @click.native.stop
            />
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" icon small @click.native.stop.prevent="doneEditAlias">
              <v-icon small>
                mdi-check
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <CommentDialog :target="target">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" color="primary" v-bind="attrs" outlined v-on="on">
              <v-icon style="margin-right: 0.5em;">
                mdi-database-import
              </v-icon>
              {{ $t('page.panels.comment_send_data') }}
            </v-btn>
          </template>
        </CommentDialog>

        <v-btn color="primary" icon @click.native.stop="copyTarget">
          <v-icon small>
            mdi-content-copy
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          icon
          :disabled="targets.length <= 1"
          @click.native.stop="removeTarget"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CommentDialog from './CommentDialog'
import { mapState } from 'vuex'

export default {
  name: 'TargetHeader',

  components: {
    CommentDialog
  },

  props: {
    target: {
      type: Object,
      required: true
    }
  },

  data: vm => ({
    isEditAlias: false,
    originAlias: vm.refId
  }),

  computed: {
    ...mapState({
      formData: state => state.panels.form.formData
    }),

    targets() {
      return this.formData.targets
    },

    alias: {
      get() {
        return this.target.refId
      },
      set(newVal) {
        const newRefId = newVal.trim()

        this.target.refId = newRefId
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      return this.$refs.form.resetValidation()
    },
    removeTarget() {
      this.$store.dispatch('panels/form/removeTarget', this.target)
    },
    copyTarget() {
      this.$store.dispatch('panels/form/copyTarget', this.target)
    },
    editAlias() {
      this.originAlias = this.alias
      this.isEditAlias = true
    },
    doneEditAlias() {
      if (!this.alias) {
        this.alias = this.originAlias
      }
      this.isEditAlias = false

      const override = this.formData.fieldConfig.overrides.find(o => o.matcher.options === this.originAlias)

      if (override) {
        override.matcher.options = this.alias
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
