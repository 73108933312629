<template>
  <v-expansion-panels v-model="showPanels" dense multiple>
    <v-expansion-panel
      v-for="(target, iTarget) in targets"
      :key="iTarget"
      class="target-expansion-panel"
    >
      <v-expansion-panel-header>
        <template v-slot:actions>
          <v-icon class="expansion-panel-header-icon font-weight-bold">
            $expand
          </v-icon>
        </template>
        <TargetHeader
          ref="targetHeaders"
          class="expansion-panel-header-text"
          :target="target"
          style="max-width: calc(100% - 24px);"
        />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <TargetItem ref="targetItems" :target="target" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import TargetHeader from './TargetHeader'
import TargetItem from './TargetItem'

import { mapState } from 'vuex'

export default {
  name: 'TargetEditor',

  components: {
    TargetHeader,
    TargetItem
  },

  data: () => ({
    showPanels: [0]
  }),

  computed: {
    ...mapState({
      formData: state => state.panels.form.formData
    }),

    targets() {
      return this.formData.targets
    }
  },

  methods: {
    validate() {
      if (!Array.isArray(this.targets) || !this.targets.length) {
        return Promise.resolve(true)
      }

      return Promise.all([
        ...this.$refs.targetHeaders.map(el => el.validate()),
        ...this.$refs.targetItems.map(el => el.validate())
      ]).then(results => results.every(Boolean))
    },
    resetValidation() {
      if (!Array.isArray(this.targets) || !this.targets.length) {
        return
      }

      return [
        ...this.$refs.targetHeaders,
        ...this.$refs.targetItems
      ].forEach(el => el.resetValidation())
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion-panel-header-icon {
  order: 0;
}

.expansion-panel-header-text {
  order: 1;
}

.v-expansion-panels {
  // background-color: transparent;
  // padding: 8px 28px;

  .v-expansion-panel.target-expansion-panel {
    background-color: #f5f5f5;

    // border: 1px solid #ddd;

    &::before {
      box-shadow: none;
    }

    &:not(:first-child)::after {
      border-color: transparent;
    }
  }
}
</style>
