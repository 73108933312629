<template>
  <v-form ref="form">
    <v-row class="tag-row flex-nowrap" no-gutters align="start">
      <div class="triangle-bordered">
        <div class="inner-triangle" />
      </div>

      <v-col class="tag-col-input">
        <v-combobox
          v-if="hasTagKeyOptions || isLoadingTags"
          v-model="tag.key"
          class="tag-input"
          solo
          hide-details="auto"
          :items="tagKeyOptions"
          :loading="isLoadingTags"
          :rules="rulesTagKey"
        />
        <v-text-field
          v-else
          v-model="tag.key"
          class="tag-input"
          solo
          :rules="rulesTagKey"
        />
      </v-col>

      <v-col class="mx-3" cols="auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="operator-btn"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                {{ selectedOperatorIcon }}
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(operator, iOperator) in tagOperatorOptions"
              :key="iOperator"
            >
              <v-btn text @click="onSelectOperator(operator)">
                <v-icon>{{ operator.icon }}</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col class="tag-col-input">
        <v-text-field
          v-if="tag.isGeojsonVariable"
          class="tag-input"
          :value="formatTagTemplate(tag)"
          readonly
          solo
          hide-details
          dense
        />
        <v-text-field
          v-else
          v-model="tag.value"
          class="tag-input"
          solo
          hide-details
          dense
        />
      </v-col>

      <v-col class="ml-3" cols="auto">
        <v-checkbox
          v-model="tag.isGeojsonVariable"
          class="mt-0 align-center"
          :label="$t('page.panels.feature_property')"
          hide-details="auto"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('page.panels.feature_property_tip') }}</span>
            </v-tooltip>
          </template>
        </v-checkbox>
      </v-col>

      <v-spacer />

      <v-col cols="auto" align-self="center">
        <!-- <v-btn color="primary" icon :disabled="isLastestTag" @click="removeTag"> -->
        <v-btn color="primary" icon @click="removeTag">
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  tagOperatorOptions,
  formatTagTemplate
} from '@/assets/js/grafana/model'

import { mapState } from 'vuex'

// import { validateRequired, validateNotEnNumUl } from '@/assets/js/validate'
import { validateRequired } from '@/assets/js/validate'

// const rulesTagKey = [validateRequired, validateNotEnNumUl]
const rulesTagKey = () => [validateRequired]

export default {
  name: 'TagItem',

  props: {
    tags: {
      type: Array,
      default: () => []
    },
    tag: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    tagOperatorOptions

  }),

  computed: {
    ...mapState({
      tagKeyOptions: state => state.panels.form.tagKeys,
      isLoadingTags: state => state.panels.form.isLoadingTags
    }),
    rulesTagKey,

    selectedOperatorIcon() {
      return this.tagOperatorOptions.find(o => o.value === this.tag.operator)
        ?.icon
    },
    hasTagKeyOptions() {
      return Array.isArray(this.tagKeyOptions) && this.tagKeyOptions.length > 0
    },
    isLastestTag() {
      return this.tags.length <= 1
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    formatTagTemplate,
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      return this.$refs.form.resetValidation()
    },
    removeTag() {
      this.$emit('remove')
    },
    onSelectOperator(operator) {
      this.tag.operator = operator.value
    }
  }
}
</script>

<style lang="scss" scoped>
$border-width: 1px;
$border-color: #ddd;
$row-bg-color: #fff;
$triangle-size: 18px;
$inner-triangle-size: $triangle-size - $border-width;

.tag-row {
  position: relative;
  padding: 12px 24px;
  border: solid $border-width $border-color;
  border-radius: 4px;

  .tag-col-input {
    flex-basis: 206px;
    max-width: 300px;
  }

  .triangle-bordered {
    position: absolute;
    bottom: -1 * $triangle-size;
    left: 5%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $triangle-size $triangle-size 0 $triangle-size;
    border-color: $border-color transparent transparent transparent;

    .inner-triangle {
      position: relative;
      top: -1 * $inner-triangle-size - $border-width;
      left: -1 * $inner-triangle-size;
      width: 0;
      height: 0;
      border-style: solid;
      border-width:
        $inner-triangle-size $inner-triangle-size 0
        $inner-triangle-size;
      border-color: $row-bg-color transparent transparent transparent;
    }
  }

  .operator-btn {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 6px;
  }
}

.v-input.tag-input {
  ::v-deep .v-input__control {
    min-height: 32px;

    & > .v-input__slot {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    .v-input__slot {
      min-height: 32px;

      input,
      .v-select__selection.v-select__selection--comma {
        color: $color-primary;
      }

      // fieldset {
      //   border-color: #eee;
      // }

      .v-input__prepend-inner {
        margin-top: 4px;
      }
    }
  }
}
</style>
