<template>
  <v-row no-gutters style="height: 100%;">
    <v-col class="white py-4 px-7">
      <v-row
        class="block-title"
        no-gutters
        align="center"
      >
        <v-col cols="auto">
          樣式
        </v-col>
        <v-col class="ml-3" cols="auto">
          <v-btn-toggle
            v-model="target.properties.custom.drawStyle"
            dense group mandatory
          >
            <v-btn value="line" small>
              Line
            </v-btn>
            <v-btn value="bars" small>
              Bars
            </v-btn>
            <v-btn value="points" small>
              Points
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <LineStyleEditor
        v-if="drawStyle === 'line'"
        class="block-content"
        :properties="target.properties"
      />
      <BarsStyleEditor
        v-if="drawStyle === 'bars'"
        class="block-content"
        :properties="target.properties"
      />
      <PointsStyleEditor
        v-if="drawStyle === 'points'"
        class="block-content"
        :properties="target.properties"
      />
    </v-col>
  </v-row>
</template>

<script>
import LineStyleEditor from './LineStyleEditor'
import BarsStyleEditor from './BarsStyleEditor'
import PointsStyleEditor from './PointsStyleEditor'

import dot from 'dot-object'

import { mapState } from 'vuex'

dot.keepArray = true

export default {
  name: 'PanelStyleItem',

  components: {
    LineStyleEditor,
    BarsStyleEditor,
    PointsStyleEditor
  },

  props: {
    target: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      formData: state => state.panels.form.formData
    }),

    defaults() {
      return this.formData.fieldConfig.defaults
    },

    override() {
      return this.formData.fieldConfig.overrides.find(o => o.matcher.options === this.target.refId)
    },

    drawStyle() {
      return this.target.properties.custom.drawStyle
    }
  },

  watch: {
    target: {
      handler() {
        this.formatProperties()
      },
      immediate: true
    }
  },

  methods: {
    formatProperties() {
      // 使用fieldConfig.default做初始值，再用已經有的override properties蓋上去

      const properties = Object.fromEntries(this.override.properties.map(p => [p.id, p.value]))

      // 用this.$set讓target底下新增一個可以響應的properties物件, 子層的雙向綁定才會起作用
      this.$set(this.target, 'properties', dot.object({
        ...dot.dot(this.defaults),
        ...dot.dot(properties)
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.block-title {
  margin-bottom: 12px;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  color: $color-primary;
}

.block-content {
  padding: 0 0.75rem;
  border: solid 1px #ddd;
  border-radius: 5px;

  .block-item-title {
    margin-bottom: 12px;
  }
}

.style-item {
  & + .style-item {
    margin-top: 4px;
  }

  .v-input--selection-controls {
    padding-top: 0;
    margin-top: 0;
  }
}
</style>
