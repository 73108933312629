<template>
  <v-form ref="form" style="height: 100%;" @submit.prevent>
    <v-row
      class="flex-nowrap"
      no-gutters
      style=" padding: 0 72px;height: 100%;"
    >
      <v-col class="step1-col white px-9 pt-6" style="flex-basis: 496px;">
        <p class="step1-col-title mb-4">
          <span v-if="isEditModel">{{ $t('page.panels.edit_panel_title') }}</span>
          <span v-else>{{ $t('page.panels.add_panel_title') }}</span>
        </p>
        <v-text-field
          v-model.trim="name"
          class="name-input"
          :rules="rulesName"
          outlined
          dense
          :placeholder="$t('page.panels.placeholder_panel_name')"
        />

        <v-alert
          class="panel-adder-step-1-tip"
          type="info"
          elevation="0"
          icon="mdi-information-outline"
          text
        >
          <i18n path="page.panels.add_panel_content" tag="span">
            <br>
          </i18n>
        </v-alert>
      </v-col>
      <v-col class="step1-col white ml-3 px-9 py-6" style="flex-basis: 1268px;">
        <p class="step1-col-title mb-4">
          {{ $t('page.panels.choose_panel_type') }}
        </p>

        <v-item-group
          v-model="type"
          class="d-flex justify-center"
          active-class="graph-card-active"
          mandatory
        >
          <v-row
            class="graph-wrap flex-grow-0"
            no-gutters
            style="width: calc(2 * (290px + 6px + 24px));"
          >
            <v-col
              v-for="(graph, iGraph) in graphs"
              :key="iGraph"
              class="pa-3"
              cols="auto"
            >
              <v-item v-slot="{ toggle }">
                <v-card
                  class="graph-card"
                  outlined
                  :disabled="graph.value !== 'timeseries'"
                  @click="toggle"
                >
                  <v-card-title
                    class="primary--text justify-center text-weight-bold"
                    style="font-size: 1.5rem;"
                  >
                    {{ graph.text }}
                  </v-card-title>
                  <!-- <img :src="changeFileType(graph.src, 'webp')" /> -->
                  <!-- <div> -->
                  <v-row justify="center" no-gutters>
                    <picture>
                      <source
                        v-for="(source, iSource) in graph.sources"
                        :key="iSource"
                        :srcset="source.src"
                        :type="source.type"
                      >
                      <!-- <v-img :src="graph.src" width="320"></v-img> -->
                      <v-img :src="graph.src" width="290" />
                    </picture>
                  </v-row>
                  <!-- </div> -->
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validateRequired, validateDuplicate } from '@/assets/js/validate'

import { mapState, mapGetters } from 'vuex'

const rulesName = vm => [
  validateRequired,
  validateDuplicate(vm.panelNames)
]

export default {
  name: 'PanelAdderGeneral',

  computed: {
    ...mapState({
      formData: state => state.panels.form.formData
    }),
    ...mapGetters({
      graphs: 'panels/form/graphs',
      getPanelsByRoute: 'dashboards/getPanelsByRoute',
      getPanelByRoute: 'dashboards/getPanelByRoute'
    }),
    rulesName,

    isEditModel() {
      return this.$route.name === 'PanelEditor'
    },
    panels() {
      return this.getPanelsByRoute(this.$route)
    },
    editPanel() {
      return this.getPanelByRoute(this.$route)
    },
    panelNames() {
      const names = this.panels
        .filter(panel => panel.title !== this.editPanel?.title)
        .map(panel => panel.title)

      return names
    },

    name: {
      get() {
        return this.formData.title
      },
      set(newVal) {
        this.$store.dispatch('panels/form/setFormData', {
          title: newVal
        })
      }
    },
    type: {
      get() {
        const index = this.graphs.findIndex(
          graph => graph.value === this.formData.type
        )

        return index
      },
      set(newVal) {
        const type = this.graphs[newVal]?.value

        this.$store.dispatch('panels/form/setFormData', {
          type
        })
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.step1-col {
  overflow-y: auto;
  height: 100%;

  .step1-col-title {
    font-size: 1.375rem;
    color: #343843;
  }
}

.v-input.v-text-field--outlined.name-input {
  ::v-deep .v-input__slot {
    min-height: 45px;
  }
}

.v-input.v-text-field--outlined.name-input:not(.v-input--has-state) {
  ::v-deep .v-input__slot {
    fieldset {
      border-color: #eee;
    }
  }
}

.panel-adder-step-1-tip.v-alert {
  font-size: 1rem;

  &::before {
    background-color: transparent;
  }

  ::v-deep .v-alert__wrapper {
    .v-alert__content {
      color: #343843;
    }
  }
}

.graph-card {
  border: 3px solid transparent;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

  &.graph-card-active {
    border-color: rgba(27, 150, 176, 0.5);
  }
}
</style>
